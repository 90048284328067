<template>
  <div>
    <ts-page-title
      :title="$t('overtimeRuleByShift.pageTitle')"
      :breadcrumb="[
        { text: $t('home'), href: '/' },
        {
          text: $t('overtimeRuleByShift.pageTitle'),
          active: true,
        },
      ]"
    />
    <ts-panel>
      <ts-panel-wrapper class="tw-flex tw-items-center tw-justify-between">
        <Button type="info" ghost @click="addNew">{{
          $t("addNew")
        }}</Button>
        <div class="tw-justify-end tw-space-x-2">
          <Input
            search
            v-model="search"
            :placeholder="$t('overtimeRuleByShift.search')"
            :style="width"
            @on-focus="() => (width = 'width: 500px')"
            @on-blur="() => (width = 'width: 300px')"
          />
        </div>
      </ts-panel-wrapper>
      <Table
        :columns="columns"
        :data="resources"
        size="small"
        :loading="loading"
        max-height="700"
        stripe
      >
        <template slot-scope="{ row }" slot="shift_name">
          {{
            row.shift_working_hours ? row.shift_working_hours.shift_name : ""
          }}
        </template>
        <template slot-scope="{ row }" slot="keyword">
          {{ row.shift_working_hours ? row.shift_working_hours.keyword : "" }}
        </template>
        <template slot-scope="{ row }" slot="ot_name">
          {{ row.overtime_item ? row.overtime_item.ot_name : "" }}
        </template>

        <template slot-scope="{ row }" slot="action">
          <a
            href="#"
            class="text-primary ml-2"
            @click.prevent="onEdit(row)"
            v-tooltip="$t('edit')"
          >
            <Icon type="ios-create" size="20" />
          </a>
          <Poptip
            confirm
            :title="$t('areYouSure')"
            @on-ok="onDelete(row)"
            :transfer="true"
            :ok-text="$t('yes')"
            :cancel-text="$t('no')"
          >
            <a
              class="ml-2 text-danger"
              v-tooltip="$t('delete')"
              :disabled="row._deleting"
            >
              <i class="fas fa-circle-notch fa-spin" v-if="row._deleting"></i>
              <Icon type="ios-trash" size="20" v-else />
            </a>
          </Poptip>
        </template>
      </Table>
      <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
        <ts-i-pagination
          v-model="pagination"
          @navigate="fetchData"
        ></ts-i-pagination>
      </div>
      <Modal
        v-model="showForm"
        draggable
        sticky
        scrollable
        :mask="false"
        :footer-hide="true"
        :z-index="1022"
        width="700px"
        :title="$t('overtimeRuleByShift.pageTitle')"
      >
        <from-action
          ref="form_action"
          @cancel="clearEdit"
          @fetchData="fetchData"
        />
      </Modal>
    </ts-panel>
  </div>
</template>
<script>
import FromAction from "./form.vue";
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
  name: "payroll-cycle",
  components: {
    FromAction,
  },
  data() {
    return {
      loading: false,
      showForm: false,
      width: "width: 300px",
    };
  },
  computed: {
    ...mapState("payroll/overtimeRuleByShift", ["resources", "pagination"]),
    search: {
      get() {
        return this.$store.state.payroll.overtimeRuleByShift.search;
      },
      set(newValue) {
        this.$store.commit("payroll/overtimeRuleByShift/SET_SEARCH", newValue);
        this.$store.commit("payroll/overtimeRuleByShift/RESET_CURRENT_PAGE");
      },
    },
    columns() {
      return [
        {
          title: this.$t("overtimeRuleByShift.shiftName"),
          slot: "shift_name",
          // key: "cycle_year",
          // sortable: true,
          minWidth: 150,
        },
        {
          title: this.$t("overtimeRuleByShift.keyword"),
          slot: "keyword",
          // key: "cycle_month_display",
          // sortable: true,
          minWidth: 150,
        },
        {
          title: this.$t("overtimeRuleByShift.otName"),
          slot: "ot_name",
          // key: "cycle_status",
          // sortable: true,
          minWidth: 180,
        },
        {
          title: this.$t("overtimeRuleByShift.timeFrom"),
          key: "time_from",
          sortable: true,
          minWidth: 180,
          align: "center",
        },
        {
          title: this.$t("overtimeRuleByShift.timeTo"),
          key: "time_to",
          sortable: true,
          minWidth: 180,
          align: "center",
        },
        {
          title: this.$t("actions"),
          key: "action",
          slot: "action",
          align: "center",
          className: "tw-space-x-2",
          width: 90,
        },
      ];
    },
  },
  methods: {
    addNew() {
      this.$store.commit("payroll/overtimeRuleByShift/SET_EDIT_DATA", {});
      this.showForm = true;
      this.$refs.form_action.clearInput();
      this.$refs.form_action.setEditData();
    },
    fetchData(attributes) {
      this.loading = true;
      this.$store
        .dispatch("payroll/overtimeRuleByShift/fetch", attributes)
        .catch((error) => {
          this.loading = false;
          this.notice({ type: "error", text: error.message });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async onDelete(record) {
      record._deleting = true;
      this.$store
        .dispatch("payroll/overtimeRuleByShift/destroy", record.id)
        .then((response) => {
          this.fetchData();
          record._deleting = false;
          this.notice({
            type: response.message_id == 0 ? "success" : "warning",
            text: response.message,
          });
        })
        .catch((error) => {
          this.notice({ type: "error", text: error.message });
          record._deleting = false;
        });
    },
    onEdit(record) {
      this.showForm = true;
      this.$store.commit("payroll/overtimeRuleByShift/SET_EDIT_DATA", record);
      this.$refs.form_action.setEditData();
    },
    clearEdit() {
      this.showForm = false;
      this.$refs.form_action.clearInput();
    },
    notice(not) {
      this.$Notice[not.type]({
        title: "OVERTIME RULE BY SHIFT",
        desc: not.text,
      });
    },
  },
  watch: {
    search: debounce(function (search) {
      this.fetchData({ search });
    }, 500),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchData();
    });
  },
};
</script>
